import { createI18n } from 'vue-i18n'
import axios from 'axios'
import english from './locales/en-US.json'

const defaultLanguage = 'en-US';
const defaultMessages = { 'en-US': english }

const numberFormatsTemplate = {
  '2dec': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  '3dec': { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  '4dec': { minimumFractionDigits: 4, maximumFractionDigits: 4 }
}

const dateTimeFormatsTemplate = {
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  med: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric'
  }
}

const supportLanguages = ['cs-CZ', 'cs', 'de-DE', 'de', 'en-GB', 'en-IE', 'en-US', 'en', 'es-ES', 'es', 'fr-BE', 'fr-FR', 'fr', 'nl-BE', 'nl-NL', 'nl', 'pl-PL', 'pl', 'pt-PT', 'pt', 'sk-SK', 'sk']
const languageNativeNames = {
  'cs': 'Čeština',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'pt': 'Português',
  'sk': 'Slovenčina'
}

var numberFormats = {
  'en-US': numberFormatsTemplate
}// eslint-disable-line

var dateTimeFormats = {
  'en-US': dateTimeFormatsTemplate
}// eslint-disable-line

function verifyLanguage(lang) {
  if (lang.indexOf('-') < 0) {
    lang = lang.toLowerCase()
  } else {
    let array = lang.split('-')
    lang = array[0].toLowerCase() + '-' + array[1].toUpperCase()
  }
  if (supportLanguages.indexOf(lang) < 0) {
    lang = defaultLanguage
  }
  //setup language native name
  let langKey = lang.indexOf('-') < 0 ? lang : lang.split('-')[0].toLowerCase()
  let languageName = 'English'
  if (languageNativeNames[langKey] !== undefined) {
    languageName = languageNativeNames[langKey]
  }
  return { lang: lang, languageName: languageName }
}

 // Set new locale.
export function setLocale(locale) {
  const verifyResult = verifyLanguage(locale)
  locale = verifyResult.lang
  i18n.languageName = verifyResult.languageName
  if (!i18n.global.availableLocales.includes(locale)) {
    return import(/* webpackChunkName: "lang-[request]" */ `./locales/${locale}`)
      .then(msgs => {
        return intialLanguage(locale, msgs);
      })
      .catch(() => {
        return intialLanguage(defaultLanguage, defaultMessages['en-US']);
      })
  }
}

const intialLanguage = (lang, messages) => { // eslint-disable-line
  numberFormats[lang] = numberFormatsTemplate
  dateTimeFormats[lang] = dateTimeFormatsTemplate
  i18n.global.setLocaleMessage(lang, messages);
  i18n.global.locale.value = lang;
  return setI18nLanguage(lang)
}

const setI18nLanguage = (lang) => {
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export const i18n = createI18n({
  globalInjection: true,
  legacy: false, //default is true, you must set `false`, to use Composition API
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  preserveDirectiveContent: true,
  allowComposition: true,
  dateTimeFormats,
  numberFormats,
  messages: defaultMessages,
  languageName: 'English',
  missingWarn: false, // Composition  [intlify] Not found 'ml_107448' key in 'en' locale messages.
})
