import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow } from "vue"
import _imports_0 from '../assets/balloon.png'
import _imports_1 from '../assets/LPG.png'
import _imports_2 from '../assets/refrigerant.png'
import _imports_3 from '../assets/welding equipment.png'
import _imports_4 from '../assets/banks.png'
import _imports_5 from '../assets/medical.png'
import _imports_6 from '../assets/top gas.png'
import _imports_7 from '../assets/welding.png'
import _imports_8 from '../assets/wine.png'
import _imports_9 from '../assets/food.png'
import _imports_10 from '../assets/cylinder for life.png'
import _imports_11 from '../assets/maxx cut and weld.png'
import _imports_12 from '../assets/delivery.png'
import _imports_13 from '../assets/forklift.png'
import _imports_14 from '../assets/toolhire.png'


const _hoisted_1 = {
  key: 0,
  class: "margin-bottom-20 d-none d-md-block"
}
const _hoisted_2 = { class: "switch" }
const _hoisted_3 = { class: "agent-marker" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "agent-info" }
const _hoisted_6 = { class: "agent-info" }
const _hoisted_7 = { class: "agent-info" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "agent-info" }
const _hoisted_11 = { class: "agent-info" }
const _hoisted_12 = ["title", "alt"]
const _hoisted_13 = ["title", "alt"]
const _hoisted_14 = ["title", "alt"]
const _hoisted_15 = ["title", "alt"]
const _hoisted_16 = ["title", "alt"]
const _hoisted_17 = ["title", "alt"]
const _hoisted_18 = ["title", "alt"]
const _hoisted_19 = ["title", "alt"]
const _hoisted_20 = ["title", "alt"]
const _hoisted_21 = ["title", "alt"]
const _hoisted_22 = ["title", "alt"]
const _hoisted_23 = ["title", "alt"]
const _hoisted_24 = { class: "agent-info" }
const _hoisted_25 = ["title", "alt"]
const _hoisted_26 = ["title", "alt"]
const _hoisted_27 = ["title", "alt"]
const _hoisted_28 = { class: "agent-info" }
const _hoisted_29 = { class: "ap-green margin-bottom-20" }
const _hoisted_30 = { class: "ap-green" }
const _hoisted_31 = { class: "red-msg" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col")
  const _component_b_row = _resolveComponent("b-row")
  const _component_b_link = _resolveComponent("b-link")
  const _component_icons = _resolveComponent("icons")
  const _component_Icons = _resolveComponent("Icons")
  const _component_b_card = _resolveComponent("b-card")
  const _component_b_form_input = _resolveComponent("b-form-input")
  const _component_b_form_group = _resolveComponent("b-form-group")
  const _component_multiselect = _resolveComponent("multiselect")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_container = _resolveComponent("b-container")
  const _directive_t = _resolveDirective("t")
  const _directive_b_tooltip = _resolveDirective("b-tooltip")

  return (_openBlock(), _createBlock(_component_b_container, { fluid: "" }, {
    default: _withCtx(() => [
      ($options.agentsEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            ($options.mlString.introduction1.toUpperCase() != 'BLANK')
              ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, null, {
                      default: _withCtx(() => [
                        _createElementVNode("label", null, _toDisplayString($options.mlString.introduction1), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            ($options.mlString.introduction2.toUpperCase() != 'BLANK')
              ? (_openBlock(), _createBlock(_component_b_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, null, {
                      default: _withCtx(() => [
                        _createElementVNode("label", null, _toDisplayString($options.mlString.introduction2), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            ($options.mlString.introduction3.toUpperCase() != 'BLANK')
              ? (_openBlock(), _createBlock(_component_b_row, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, null, {
                      default: _withCtx(() => [
                        _createElementVNode("label", null, _toDisplayString($options.mlString.introduction3), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!$options.agentsEmpty)
        ? (_openBlock(), _createBlock(_component_b_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, {
                cols: "auto",
                class: _normalizeClass({ 'd-none': $data.embeded, 'd-lg-none': true })
              }, {
                default: _withCtx(() => [
                  _createElementVNode("label", _hoisted_2, [
                    _createElementVNode("input", {
                      type: "checkbox",
                      id: "map-switch",
                      checked: "checked",
                      onChange: _cache[0] || (_cache[0] = (...args) => ($options.mapSwitchChanged && $options.mapSwitchChanged(...args)))
                    }, null, 32),
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "slider" }, null, -1))
                  ])
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            class: _normalizeClass({
          'd-none': $data.showMap || $options.agentsEmpty,
          'd-lg-block': !$options.agentsEmpty,
        })
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.agents, (ag, index) => {
                return (_openBlock(), _createBlock(_component_b_card, {
                  "no-body": "",
                  key: index,
                  class: "mb-1 ps-3 pe-2 pt-2 pb-2 agent-details"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_row, { "align-v": "center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_col, {
                                  class: "pe-1",
                                  cols: "auto"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(index + 1), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_b_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_link, {
                                      href: "#",
                                      onClick: $event => ($options.agentLink(ag.url))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h6", null, [
                                          _createTextVNode(_toDisplayString(ag.name), 1),
                                          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                                          _createTextVNode(_toDisplayString(ag.name2), 1)
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        (ag.agentLogoGraphic)
                          ? (_openBlock(), _createBlock(_component_b_col, {
                              key: 0,
                              md: "auto",
                              "align-self": "start",
                              class: "d-none d-md-block"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  alt: "Image placeholder",
                                  height: "30px",
                                  src: `data:image/png;base64,${ag.agentLogoGraphic}`
                                }, null, 8, _hoisted_4)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(ag.address1) + " " + _toDisplayString(ag.street) + " " + _toDisplayString(ag.address2) + " " + _toDisplayString(ag.additionalAddress), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icons, { iconType: 'compass' }),
                            _createElementVNode("span", _hoisted_5, _toDisplayString(ag.distance), 1),
                            _createVNode(_component_b_link, {
                              href: "#",
                              style: {"color":"black","margin-left":"3px"},
                              onClick: $event => ($options.getNavigation(ag.latitude, ag.longitude))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_icons, { iconType: 'navigation' })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_b_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Icons, { iconType: 'info' }),
                            _createElementVNode("span", _hoisted_6, _toDisplayString(ag.agentID), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icons, { iconType: 'person' }),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(ag.contact), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_b_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icons, { iconType: 'phone' }),
                            _createElementVNode("a", {
                              class: "agent-info-a",
                              href: 'tel:' + ag.telephone
                            }, _toDisplayString(ag.telephone), 9, _hoisted_8)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_b_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icons, { iconType: 'envelope' }),
                            _createElementVNode("a", {
                              class: "agent-info-a",
                              href: 'mailto:' + ag.email
                            }, _toDisplayString(ag.email), 9, _hoisted_9)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_b_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icons, { iconType: 'clock' }),
                            _createElementVNode("span", _hoisted_10, _toDisplayString(ag.businessHours), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_b_row, { class: "flag-icon" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, {
                          cols: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_11, _cache[8] || (_cache[8] = [
                              _createTextVNode("X - Products")
                            ]))), [
                              [_directive_t, { path: 'ml_109756', locale: this.$i18n.locale }]
                            ]),
                            _cache[9] || (_cache[9] = _createTextVNode(": ")),
                            (ag.balloonGasFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  title: $options.mlString.flags.balloonGasFlag,
                                  alt: $options.mlString.flags.balloonGasFlag,
                                  src: _imports_0
                                }, null, 8, _hoisted_12)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.lpgFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  title: $options.mlString.flags.lpgFlag,
                                  alt: $options.mlString.flags.lpgFlag,
                                  src: _imports_1
                                }, null, 8, _hoisted_13)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.refrigerantFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 2,
                                  title: $options.mlString.flags.refrigerantFlag,
                                  alt: $options.mlString.flags.refrigerantFlag,
                                  src: _imports_2
                                }, null, 8, _hoisted_14)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.equipmentFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 3,
                                  title: $options.mlString.flags.equipmentFlag,
                                  alt: $options.mlString.flags.equipmentFlag,
                                  src: _imports_3
                                }, null, 8, _hoisted_15)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.banksFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 4,
                                  title: $options.mlString.flags.banksFlag,
                                  alt: $options.mlString.flags.banksFlag,
                                  src: _imports_4
                                }, null, 8, _hoisted_16)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.medicalFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 5,
                                  title: $options.mlString.flags.medicalFlag,
                                  alt: $options.mlString.flags.medicalFlag,
                                  src: _imports_5
                                }, null, 8, _hoisted_17)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.topGasFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 6,
                                  title: $options.mlString.flags.topGasFlag,
                                  alt: $options.mlString.flags.topGasFlag,
                                  src: _imports_6
                                }, null, 8, _hoisted_18)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.weldingFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 7,
                                  title: $options.mlString.flags.weldingFlag,
                                  alt: $options.mlString.flags.weldingFlag,
                                  src: _imports_7
                                }, null, 8, _hoisted_19)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.wineFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 8,
                                  title: $options.mlString.flags.wineFlag,
                                  alt: $options.mlString.flags.wineFlag,
                                  src: _imports_8
                                }, null, 8, _hoisted_20)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.foodFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 9,
                                  title: $options.mlString.flags.foodFlag,
                                  alt: $options.mlString.flags.foodFlag,
                                  src: _imports_9
                                }, null, 8, _hoisted_21)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.cylinderForLife === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 10,
                                  title: $options.mlString.flags.cylinderForLife,
                                  alt: $options.mlString.flags.cylinderForLife,
                                  src: _imports_10
                                }, null, 8, _hoisted_22)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.maxxCutWeld === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 11,
                                  title: $options.mlString.flags.maxxCutWeld,
                                  alt: $options.mlString.flags.maxxCutWeld,
                                  src: _imports_11
                                }, null, 8, _hoisted_23)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_b_col, {
                          cols: "6",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_24, _cache[10] || (_cache[10] = [
                              _createTextVNode("X - Services")
                            ]))), [
                              [_directive_t, { path: 'ml_109757', locale: this.$i18n.locale  }]
                            ]),
                            _cache[11] || (_cache[11] = _createTextVNode(": ")),
                            (ag.deliveryFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  title: $options.mlString.flags.deliveryFlag,
                                  alt: $options.mlString.flags.deliveryFlag,
                                  src: _imports_12
                                }, null, 8, _hoisted_25)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.forkliftTruckFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  title: $options.mlString.flags.forkliftTruckFlag,
                                  alt: $options.mlString.flags.forkliftTruckFlag,
                                  src: _imports_13
                                }, null, 8, _hoisted_26)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true),
                            (ag.toolHireFlag === 'Y')
                              ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                  key: 2,
                                  title: $options.mlString.flags.toolHireFlag,
                                  alt: $options.mlString.flags.toolHireFlag,
                                  src: _imports_14
                                }, null, 8, _hoisted_27)), [
                                  [
                                    _directive_b_tooltip,
                                    void 0,
                                    void 0,
                                    { hover: true }
                                  ]
                                ])
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    (ag.comment != '')
                      ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_col, null, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_28, _toDisplayString(ag.comment), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_b_col, {
            class: _normalizeClass({
          'd-none': !$data.showMap && !$options.agentsEmpty,
          'd-lg-block': !$options.agentsEmpty,
        })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, {
                    class: _normalizeClass({ 'col-md-6': $options.agentsEmpty, 'col-md-12': !$options.agentsEmpty })
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_col, null, {
                            default: _withCtx(() => [
                              _withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_29, _cache[12] || (_cache[12] = [
                                _createTextVNode(" x-Find a Local Supplier ")
                              ]))), [
                                [_directive_t, { path: $options.agentsEmpty ? 'ml_12148' : 'ml_109755', locale: this.$i18n.locale  }]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      ($options.agentsEmpty)
                        ? (_openBlock(), _createBlock(_component_b_row, {
                            key: 0,
                            class: "d-none d-sm-block"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_col, null, {
                                default: _withCtx(() => [
                                  _withDirectives(_createElementVNode("label", _hoisted_30, null, 512), [
                                    [_directive_t, { path: 'ml_112366', locale: this.$i18n.locale  }]
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _withDirectives(_createVNode(_component_b_form_group, {
                        id: "input-group-street",
                        label: "",
                        "label-for": "input-street",
                        class: "mb-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            id: "input-street",
                            modelValue: $data.street,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.street) = $event)),
                            placeholder: $options.mlString.street
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, $options.showControls.street]
                      ]),
                      _createVNode(_component_b_form_group, {
                        id: "input-group-town",
                        label: "",
                        "label-for": "input-town",
                        class: "mb-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            id: "input-town",
                            modelValue: $data.town,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.town) = $event)),
                            class: _normalizeClass({ 'red-border': $data.invalidInput.town }),
                            placeholder: $options.mlString.town
                          }, null, 8, ["modelValue", "class", "placeholder"])
                        ]),
                        _: 1
                      }),
                      _withDirectives(_createVNode(_component_b_form_group, {
                        id: "input-group-postalCode",
                        label: "",
                        "label-for": "input-postalCode",
                        class: "mb-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            id: "input-postalCode",
                            modelValue: $data.postalcode,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.postalcode) = $event)),
                            class: _normalizeClass({ 'red-border': $data.invalidInput.postalCode }),
                            placeholder: $options.mlString.postalCode
                          }, null, 8, ["modelValue", "class", "placeholder"])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, $options.showControls.postalCode]
                      ]),
                      _createVNode(_component_b_form_group, { class: "mb-1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_multiselect, {
                            modelValue: $data.selectedProduct,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.selectedProduct) = $event)),
                            options: $options.products,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            openDirection: "bottom",
                            "group-values": "productOptions",
                            "group-label": "selectAll",
                            "group-select": true,
                            placeholder: $options.mlString.pleaseChooseProducts,
                            label: "text",
                            "track-by": "value",
                            "preselect-first": false
                          }, null, 8, ["modelValue", "options", "placeholder"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_b_form_group, { class: "mb-1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_multiselect, {
                            modelValue: $data.selectedService,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.selectedService) = $event)),
                            options: $options.services,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            openDirection: "bottom",
                            "group-values": "serviceOptions",
                            "group-label": "selectAll",
                            "group-select": true,
                            placeholder: $options.mlString.pleaseChooseServices,
                            label: "text",
                            "track-by": "value",
                            "preselect-first": false
                          }, null, 8, ["modelValue", "options", "placeholder"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_b_form_group, { class: "mb-1" }, {
                        default: _withCtx(() => [
                          _withDirectives((_openBlock(), _createBlock(_component_b_button, {
                            class: "search-button w-100",
                            onClick: $options.searchAgents,
                            variant: "primary"
                          }, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode("x-Find agents")
                            ])),
                            _: 1
                          }, 8, ["onClick"])), [
                            [_directive_t, { path: 'ml_12090', locale: this.$i18n.locale }]
                          ])
                        ]),
                        _: 1
                      }),
                      ($data.noSearchResult)
                        ? (_openBlock(), _createBlock(_component_b_row, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_col, null, {
                                default: _withCtx(() => [
                                  _withDirectives((_openBlock(), _createElementBlock("label", _hoisted_31, _cache[14] || (_cache[14] = [
                                    _createTextVNode("x - No search results")
                                  ]))), [
                                    [_directive_t, { path: 'ml_109775', locale: this.$i18n.locale  }]
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createElementVNode("div", {
                    class: _normalizeClass({
              'col-md-6': $options.agentsEmpty,
              'col-md-12': !$options.agentsEmpty,
              'd-none d-sm-block': $options.agentsEmpty,
            })
                  }, _cache[15] || (_cache[15] = [
                    _createElementVNode("div", {
                      id: "map_canvas",
                      style: {"height":"400px","width":"100%"}
                    }, null, -1)
                  ]), 2)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}