
export default {
  name: 'Icons',
  props: {
    iconType: {
      type: String,
      default: ''
    }
  }
}
