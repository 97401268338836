import { createRouter, createWebHistory } from 'vue-router'
import Search from '../components/Search.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: {
        name: 'Search',
        component: Search
      }
    },
    {
      path: '/Search',
      name: 'Search',
      component: Search
    }
  ]
})

export default router
