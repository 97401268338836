import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  width: "1em",
  height: "1em",
  viewBox: "0 0 16 16"
}
const _hoisted_2 = {
  key: 1,
  width: "1em",
  height: "1em",
  viewBox: "0 0 16 16"
}
const _hoisted_3 = {
  key: 2,
  width: "1em",
  height: "1em",
  viewBox: "0 0 16 16"
}
const _hoisted_4 = {
  key: 3,
  width: "1em",
  height: "1em",
  viewBox: "0 0 16 16"
}
const _hoisted_5 = {
  key: 4,
  width: "1em",
  height: "1em",
  viewBox: "0 0 16 16"
}
const _hoisted_6 = {
  key: 5,
  width: "1em",
  height: "1em",
  viewBox: "0 0 16 16"
}
const _hoisted_7 = {
  key: 6,
  width: "1em",
  height: "1em",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.iconType==='info')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("g", { fill: "currentColor" }, [
            _createElementVNode("path", { d: "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" }),
            _createElementVNode("path", { d: "m8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0" })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    ($props.iconType==='phone')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("path", {
            fill: "none",
            stroke: "currentColor",
            d: "m12.283 9.313l1.572.629a.23.23 0 0 1 .145.214v4.16a.184.184 0 0 1-.184.184C7.29 14.5 2 9.21 2 2.684v-.492C2 2.086 2.086 2 2.192 2h3.652c.094 0 .179.058.214.145l.629 1.572a1.28 1.28 0 0 1-.283 1.38c-.5.498-.5 1.308 0 1.807l2.692 2.692c.5.5 1.309.5 1.808 0a1.28 1.28 0 0 1 1.379-.283Z"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    ($props.iconType==='navigation')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[2] || (_cache[2] = [
          _createElementVNode("path", {
            fill: "currentColor",
            d: "M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694L.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    ($props.iconType==='person')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[3] || (_cache[3] = [
          _createElementVNode("path", {
            fill: "currentColor",
            d: "M8 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0a2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1s1-4 6-4s6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    ($props.iconType==='envelope')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[4] || (_cache[4] = [
          _createElementVNode("path", {
            fill: "currentColor",
            d: "M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2l7-4.2V4a1 1 0 0 0-1-1zm13 2.383l-4.708 2.825L15 11.105zm-.034 6.876l-5.64-3.471L8 9.583l-1.326-.795l-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    ($props.iconType==='clock')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_6, _cache[5] || (_cache[5] = [
          _createElementVNode("g", { fill: "currentColor" }, [
            _createElementVNode("path", { d: "M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" }),
            _createElementVNode("path", { d: "M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    ($props.iconType==='compass')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[6] || (_cache[6] = [
          _createElementVNode("g", { fill: "currentColor" }, [
            _createElementVNode("path", { d: "M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016m6.5-7.5a6.5 6.5 0 1 1-13 0a6.5 6.5 0 0 1 13 0" }),
            _createElementVNode("path", { d: "m6.94 7.44l4.95-2.83l-2.83 4.95l-4.949 2.83l2.828-4.95z" })
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}