
import axios from 'axios'

let activeRequests = 0
const _axios = axios.create()
_axios.defaults.headers.common['Pragma'] = 'no-cache'
_axios.defaults.headers.common['Cache-Control'] = 'no-cache'
_axios.defaults.headers.common['Expires'] = '-1'
_axios.defaults.baseURL = process.env.VUE_APP_API_URI
console.log("baseURL", process.env.VUE_APP_API_URI);

function initializeRequest() {
  activeRequests++
  window.apci.showLoader()
}

function finalizeRequest() {
  if (--activeRequests === 0) window.apci.hideLoader()
}

_axios.interceptors.request.use(
  function(config) {
    initializeRequest()
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    finalizeRequest()
    return response
  },
  function(error) {
    if (error.response.status === 401) {
      // Reloading the page will pull down index.html, which will prompt user for login
      window.location.reload()
    } else if (error.response.status === 403) {
      // We probably want to build this into the app, but keeping it for now
      window.location.href =
        'https://apdirect.airproducts.com/CustomException/CustomExceptionPage.aspx?webId=999999&appName=GateGen2&localeId=1033&errNumber=6001'
    }
    return Promise.reject(error)
  }
)

export const $axios = _axios;

export const installaxios = (app) => {
  app.config.globalProperties.$axios = { ..._axios }
}