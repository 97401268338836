import { createApp } from 'vue'
import { createBootstrap } from 'bootstrap-vue-next'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import { installaxios } from './plugins/axios'
import App from './App.vue'
import router from './router'
import { i18n } from './i18n'

const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(installaxios)
app.use(createBootstrap())
//app.mount('#app') //Use the below approach if you want to access route query in App.vue
router.isReady().then(() => {
  app.mount('#app')
})