// constants.js
export const GoogleMapCountrySetting = {
  "GB":{
    "latitude": 54,
    "longitude": -4,
    "zoom": 5
  },
  "BE":{
   "latitude": 50.85,
   "longitude": 4.65,
   "zoom": 7
 },
 "CZ":{
   "latitude": 49.85,
   "longitude": 15,
   "zoom": 7
 },
 "FR":{
   "latitude": 46.6,
   "longitude": 3.1,
   "zoom": 5
 },
 "IE":{
   "latitude": 54.67,
   "longitude": -6.7,
   "zoom": 8
 },
 "NL":{
   "latitude": 52.31,
   "longitude": 5.61,
   "zoom": 7
 },
 "PL":{
   "latitude": 52.3,
   "longitude": 20,
   "zoom": 6
 },
 "PT":{
   "latitude": 39.8,
   "longitude": -8.0,
   "zoom": 7
 },
 "SK":{
   "latitude": 48.76,
   "longitude": 19.33,
   "zoom": 7
 },
 "ES":{
   "latitude": 40.3,
   "longitude": -3.2,
   "zoom": 7
 },
 "MY":{
   "latitude": 4,
   "longitude": 102,
   "zoom": 6
 },
 "DE":{
   "latitude": 51,
   "longitude": 10.1,
   "zoom": 5
 },
 "IC":{
   "latitude": 49.35,
   "longitude": -2.38,
   "zoom": 7
 },
 "GuernseyArea":{
   "latitude": 49.45,
   "longitude": -2.58
 },
 "JerseyArea":{
   "latitude": 49.21,
   "longitude": -2.12
 }
}

export const GetContactEmail = (locale) => {
  switch (locale.toLocaleLowerCase()) {
    case 'nl-nl':
      return 'nlinfo@airproducts.com'
    default :
      return ''
  }
}