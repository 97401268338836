
import Multiselect from 'vue-multiselect'
import * as constants from '../constants'
import {toRaw} from 'vue'

export default {
  name: 'Search',
  components: {
    Multiselect,
  },
  data() {
    return {
      embeded: this.$route.query.embeded ? this.$route.query.embeded : false,
      autoquery: this.$route.query.autoquery
        ? this.$route.query.autoquery
        : false,
      region: this.$route.query.region
        ? this.$route.query.region.toUpperCase()
        : 'GB',
      locale: this.$route.query.locale ? this.$route.query.locale : 'en-US',
      postalcode: this.$route.query.postalcode
        ? this.$route.query.postalcode
        : '',
      town: this.$route.query.town ? this.$route.query.town : '',
      street: this.$route.query.street ? this.$route.query.street : '',
      map: {},
      curLocation: {},
      showMap: false,
      mapOptions: {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      },
      markerIcon: {
        url: require('../assets/green-marker.png'),
        scaledSize: new google.maps.Size(26, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(32, 65),
        labelOrigin: new google.maps.Point(13, 14),
      },
      infoWindow: new google.maps.InfoWindow(),
      agents: [],
      selectedProduct: null,
      selectedService: null,
      markers: [],
      invalidInput: {
        town: false,
        postalCode: false,
      },
      selectedFlags: this.$route.query.selectedFlags
        ? this.$route.query.selectedFlags
        : '',
      noSearchResult: false,
    }
  },
  computed: {
    agentsEmpty() {
      return this.agents.length === 0
    },
    mlString() {
      return {
        postalCode: this.$t('ml_12089'),
        town: this.$t('ml_12088'),
        street: this.$t('ml_12087'),
        products: this.$t('ml_109756'),
        services: this.$t('ml_109757'),
        pleaseChooseProducts: this.$t('ml_109773'),
        pleaseChooseServices: this.$t('ml_109774'),
        introduction1: this.$t('ml_12035'),
        introduction2: this.$t('ml_12126'),
        introduction3: this.$t('ml_12082', { ContactEmail: constants.GetContactEmail(this.$i18n.locale) }),
        flags: {
          balloonGasFlag: this.$t('ml_12113'),
          lpgFlag: this.$t('ml_12123'),
          refrigerantFlag: this.$t('ml_12205'),
          equipmentFlag: this.$t('ml_12119'),
          banksFlag: this.$t('ml_12115'),
          medicalFlag: this.$t('ml_12114'),
          topGasFlag: this.$t('ml_12121'),
          weldingFlag: this.$t('ml_12124'),
          wineFlag: this.$t('ml_109779'),
          foodFlag: this.$t('ml_109778'),
          cylinderForLife: this.$t('ml_109780'),
          maxxCutWeld: this.$t('ml_109781'),
          deliveryFlag: this.$t('ml_12117'),
          forkliftTruckFlag: this.$t('ml_12122'),
          toolHireFlag: this.$t('ml_12120'),
        },
        selectAll: this.$t('ml_109809'),
        deSelectAll: this.$t('ml_109814'),
      }
    },
    products() {
      return [
        {
          selectAll: this.mlString.selectAll,
          productOptions: [
            { value: 'balloonGasFlag', text: this.mlString.flags.balloonGasFlag },
            { value: 'lpgFlag', text: this.mlString.flags.lpgFlag },
            { value: 'refrigerantFlag', text: this.mlString.flags.refrigerantFlag },
            { value: 'equipmentFlag', text: this.mlString.flags.equipmentFlag },
            { value: 'banksFlag', text: this.mlString.flags.banksFlag },
            { value: 'medicalFlag', text: this.mlString.flags.medicalFlag },
            { value: 'topGasFlag', text: this.mlString.flags.topGasFlag },
            { value: 'weldingFlag', text: this.mlString.flags.weldingFlag },
            { value: 'wineFlag', text: this.mlString.flags.wineFlag },
            { value: 'foodFlag', text: this.mlString.flags.foodFlag },
            { value: 'cylinderForLife', text: this.mlString.flags.cylinderForLife },
            { value: 'maxxCutWeld', text: this.mlString.flags.maxxCutWeld },
          ]
        }
      ]
    },
    services() {
      return [
        {
          selectAll: this.mlString.selectAll,
          serviceOptions: [
            { value: 'deliveryFlag', text: this.mlString.flags.deliveryFlag },
            { value: 'forkliftTruckFlag', text: this.mlString.flags.forkliftTruckFlag },
            { value: 'toolHireFlag', text: this.mlString.flags.toolHireFlag },
          ]
        }
      ]
    },
    showControls() {
      return {
        postalCode: ['IE', 'PL', 'SK'].indexOf(this.region) < 0,
        street: ['PL', 'SK'].indexOf(this.region) < 0,
      }
    },
  },
  methods: {
    mapBuild() {
      this.mapOptions.center = new google.maps.LatLng(
        constants.GoogleMapCountrySetting[this.region].latitude,
        constants.GoogleMapCountrySetting[this.region].longitude
      )
      this.mapOptions.zoom = constants.GoogleMapCountrySetting[this.region].zoom
      this.map = new google.maps.Map(
        document.getElementById('map_canvas'),
        this.mapOptions
      )
    },
    agentLink(url) {
      if (url) window.open('http://' + url)
    },
    mapSwitchChanged() {
      this.showMap = !this.showMap
    },
    searchAgents(event, lat, lng) {
      if (event != 'mapclick' && this.validateInvalidInput()) {
        return
      }
      let self = this
      self.noSearchResult = false
      if (self.embeded) {
        let url =
          window.location.href.split('?')[0] +
          '?region=' +
          encodeURIComponent(self.region) +
          '&locale=' +
          encodeURIComponent(self.locale) +
          '&street=' +
          encodeURIComponent(self.street) +
          '&postalcode=' +
          encodeURIComponent(self.postalcode) +
          '&town=' +
          encodeURIComponent(self.town) +
          '&autoquery=true' +
          '&selectedFlags=' +
          self.getSelectedFlags()
        if (lat && lng) {
          url += '&lat=' + lat + '&lng=' + lng
        }
        window.open(url)
        return
      }

      self.cleanUpMarkers()
      window.apci.showLoader()

      var url =
        'agents?street=' +
        encodeURIComponent(self.street) +
        '&town=' +
        encodeURIComponent(self.town) +
        '&postalCode=' +
        encodeURIComponent(self.postalcode) +
        '&region=' +
        encodeURIComponent(self.region) +
        '&selectedFlags=' +
        self.getSelectedFlags()
      if (lat && lng) {
        url += '&lat=' + lat + '&lng=' + lng
      }
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response)
          self.agents = response.data.agents
          self.noSearchResult = self.agents.length === 0
          if (self.agents.length === 0) {
            return
          }

          self.curLocation = new google.maps.LatLng(
            response.data.location.lat,
            response.data.location.lng
          )
          self.map.setCenter(self.curLocation)
          self.markers.push(
            new google.maps.Marker({
              position: self.curLocation,
              animation: google.maps.Animation.DROP,
              map: self.map,
              label: {
                color: 'white',
                text: 'me',
              },
            })
          )

          let destinationsArray = []
          self.agents.forEach((agent, index) => {
            destinationsArray.push(
              new google.maps.LatLng(agent.latitude, agent.longitude)
            )
          })

          let service = new google.maps.DistanceMatrixService()
          let unitSystemString = google.maps.UnitSystem.METRIC

          if (self.region === 'GB' || self.region === 'IE') {
            unitSystemString = google.maps.UnitSystem.IMPERIAL
          }
          service.getDistanceMatrix(
            {
              origins: [self.curLocation],
              destinations: destinationsArray,
              travelMode: google.maps.TravelMode.DRIVING,
              avoidHighways: false,
              avoidTolls: false,
              unitSystem: unitSystemString,
            },
            self.setDistance
          )

        })
        .finally(() => {
          window.apci.hideLoader()
        })
    },
    setDistance(response, status) {
      let self = this
      if (status === google.maps.DistanceMatrixStatus.OK) {
        let distanceResults = response.rows[0].elements
        self.agents.forEach((agent, index) => {
          let element = distanceResults[index]
          let distance = ''
          let distanceValue = 0
          if (element.distance) {
            distance = element.distance.text
            distanceValue = element.distance.value
          }
          if (distanceValue === 0) {
            distance =
              self.region === 'GB' || self.region === 'IE'
                ? '0.00 mi'
                : '0.00 km'
          }
          agent.distance = distance
          agent.distanceValue = distanceValue
        })

        //setup the sequence by distance
        self.agents.sort((a, b) => (a.distanceValue > b.distanceValue ? 1 : -1))
        if (self.agents.length > 5) {
          self.agents.length = 5
        }        
        self.setAgentsMarkerOnMap()
      }
    },
    setAgentsMarkerOnMap() {
      let self = this
      let map_bounds = new google.maps.LatLngBounds()
      self.agents.forEach((agent, index) => {
        let additionalAddress = ''
        if (self.region === 'GB' || self.region === 'IE') {
          if (agent.town != null && agent.town !== '') {
            additionalAddress += agent.town
          }
          if (agent.postalcode != null && agent.postalcode !== '') {
            additionalAddress += ' ' + agent.postalcode
          }
        } else {
          if (agent.postalcode != null && agent.postalcode !== '') {
            additionalAddress += agent.postalcode
          }
          if (agent.town != null && agent.town !== '') {
            additionalAddress += ' ' + agent.town
          }
        }
        agent.additionalAddress = additionalAddress
        let latlng = new google.maps.LatLng(agent.latitude, agent.longitude)
        let marker = new google.maps.Marker({
          map: self.map,
          title: agent.name,
          label: {
            color: 'white',
            text: (parseInt(index) + 1).toString(),
            fontSize: '14px',
          },
          animation: google.maps.Animation.DROP,
          icon: self.markerIcon,
          position: latlng,
        })
        self.markers.push(marker)
        self.showInfoWindow(marker, agent)
        map_bounds.extend(latlng)
      })
      self.map.setCenter(map_bounds.getCenter())
      self.map.fitBounds(map_bounds)
    },
    getNavigation(agLat, agLng) {
      window.open(
        'https://www.google.com/maps?saddr=' +
          this.curLocation.lat() +
          ',' +
          this.curLocation.lng() +
          '&daddr=' +
          agLat +
          ',' +
          agLng
      )
    },
    showInfoWindow(marker, agent) {
      let self = this
      marker.addListener('click', function () {
        self.infoWindow.setContent(
          "<div style = 'min-width:200px;min-height:40px'>" +
            "<div style='margin-bottom:10px; font-weight:bold;'>" +
            agent.name +
            '</div>' +
            '<div>' +
            (agent.telephone ? agent.telephone : '') +
            '</div>' +
            '<div>' +
            (agent.contact ? agent.contact : '') +
            '</div>' +
            '<div>' +
            (agent.address1 ? agent.address1 : '') +
            (agent.street ? agent.street : '') +
            (agent.address2 ? agent.address2 : '') +
            '</div>' +
            '</div>'
        )
        self.infoWindow.open(marker.get('map'), marker)
      })
    },
    cleanUpMarkers() {
      this.markers.forEach((m, i) => {
        //m.setMap(null)
        toRaw(m).setMap(null)
      })
      this.markers = []
    },
    validateInvalidInput() {
      if (['IE', 'PL', 'SK'].indexOf(this.region) > -1) {
        this.invalidInput.town = this.town.trim() === ''
      } else {
        this.invalidInput.town = this.invalidInput.postalCode =
          this.town.trim() === '' && this.postalcode.trim() === ''
      }
      return this.invalidInput.town || this.invalidInput.postalCode
    },
    getSelectedFlags() {
      let flags = ''
      this.selectedProduct.forEach((p, i) => {
        flags += p.value + ','
      })
      this.selectedService.forEach((p, i) => {
        flags += p.value + ','
      })
      return flags.slice(0, -1)
    }
  },
  mounted() {
    let self = this
    self.selectedProduct = self.products.filter(
      (p) => self.selectedFlags.indexOf(p.value) > -1
    )
    self.selectedService = self.services.filter(
      (p) => self.selectedFlags.indexOf(p.value) > -1
    )
    self.mapBuild()
    google.maps.event.addListener(self.map, 'click', function (event) {
      self.searchAgents('mapclick', event.latLng.lat(), event.latLng.lng())
    })
    if (self.autoquery) {
      self.searchAgents()
    }
    
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        self.searchAgents()
      }
    })
  },
}
